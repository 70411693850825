import {
  Allies,
  CalendarDayCircle,
  Case,
  Cup,
  Games,
  Medal,
  NavbarLogoOla,
  NewsIcon,
  Quests,
  FrontHand,
  Market,
} from '@olagg/ui-kit'
import { FiInfo } from "react-icons/fi";
import { BiGhost } from "react-icons/bi";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Flex, UnorderedList, ListItem, useMediaQuery, Box, Tooltip, Text, CloseButton } from "@chakra-ui/react"
import HamburgerButton from "./HamburgerButton"
import LanguageMenu from "./LanguageMenu"
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react";
import s from './styles.module.css'

const Menu = {
  bgColor: '#2B2640',
  bgColorHover: 'whiteAlpha.500',
  bgColorGradient: 'linear-gradient(to top, #1B094D, #300863)',
  expandedWidth: '260px',
  collapsedWidth: '60px',
  textColor: 'white',
  textColorHover: 'orange.200',
}

const LeftMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLargerThan1920] = useMediaQuery('(min-width: 1920px)')
  const [isLargerThan1450] = useMediaQuery('(min-width: 1450px)')
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const { t } = useTranslation()

  // Abrir el menu la primera vez, o si el user lo quiere abierto (solo para 1920px y mas)
  useEffect(() => {
    if (!isLargerThan1920) return;

    const userNavbarPreference = localStorage.getItem('userNavbarPreference')
    if (!userNavbarPreference) {
      handleToggleMenu()
    } else {
      setIsOpen(userNavbarPreference === 'open')
    }
  }, [isLargerThan1920])

  const handleToggleMenu = () => {
    localStorage.setItem('userNavbarPreference', isOpen ? 'closed' : 'open')
    setIsOpen(!isOpen)
  }

  const menuWidth = isOpen
    ? isMobile
      ? '100vw'
      : Menu.expandedWidth
    : isLargerThan1450
      ? Menu.collapsedWidth
      : '0px'

  const menuPadding = isOpen
    ? '5'
    : isLargerThan1450
      ? '3'
      : '0'

  const Group = ( { title, showDivider, children }: {
    title?: string,
    showDivider?: boolean,
    children: React.ReactNode
  } ) => {
    return (
      <>
        {showDivider && (isOpen || isLargerThan1450) && (
          <Flex my='10px' bgColor='whiteAlpha.400' h='1px' w='100%' />
        )}
        <ListItem color='#929292' fontSize='14px' fontWeight='400'>
          {isOpen ? title || '' : ''}
          <UnorderedList styleType='none' stylePosition='inside' spacing={0} ml='0' mt='2'>
            {children}
          </UnorderedList>
        </ListItem>
      </>
    )
  }

  const Item = ( { to, icon, label, isNew }: {
    to: string,
    icon: React.ReactElement,
    label: string,
    isNew?: boolean
  }) => {
    return (
      <Link to={to}>
        <Tooltip label={!isOpen ? label : ''} placement='right'>
          <ListItem>
            <Flex
              p='2'
              gap='2'
              bg='transparent'
              color={Menu.textColor}
              fontSize='14px'
              lineHeight='24px'
              borderRadius='5'
              alignItems='center'
              _hover={{ bgColor: Menu.bgColorHover }}
            >
              {icon}
              {isOpen && (
                <>
                  <Text noOfLines={1} overflow='hidden'>{label}</Text>
                  {isNew && (
                    <Flex
                      ml={'auto'} fontSize='11px' fontWeight={700} textTransform={'uppercase'} color='white'
                      sx={{
                        background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
                        borderRadius: '12px',
                        padding: '0 8px',
                        alignItems: 'center'
                      }}
                    >
                        {t('navbar.myAccountNav.new')}
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </ListItem>
        </Tooltip>
      </Link>
    )
  }

  return (
    <Flex>
      <Flex alignItems='center'>
        <Box onClick={handleToggleMenu} cursor='pointer' px='1' py='2' mr='3'>
          <HamburgerButton
            isOpen={isOpen}
            color="#fff"
            strokeWidth="2"
            width={20}
            height={13}
          />
        </Box>
        <Link to={ROUTES.HOME} state={{ fromLogo: true }}>
          <NavbarLogoOla height='37px' width={isMobile ? '37px' : '100px'} text={!isMobile} />
        </Link>
      </Flex>

      <UnorderedList
        position='absolute'
        top='82'
        left='0'
        ml='0'
        p={menuPadding}
        pb={isMobile ? '130px' : '5'}
        styleType='none'
        bgColor={Menu.bgColor}
        bg={Menu.bgColorGradient}
        w={menuWidth}
        h='calc(100vh - 82px)'
        overflowY='auto'
        overflowX='hidden'     
        className={`${s.menuScrollbar}`}
        transition='width 0.2s ease-out'
      >
        {isMobile && (
          <Flex justifyContent='space-between' alignItems='center' mb='3'>
            <LanguageMenu closeMenu={() => {}} />
            <CloseButton color='white' onClick={() => setIsOpen(false)} p='2'/>
          </Flex>
        )}

        <Group title={t('navbar.games')}>
          <Item
            to={ROUTES.QUESTS}
            icon={<Quests width='20px' height='20px' />}
            label={t('navbar.gamesMenu.quests')}
          />
          <Item
            to={ROUTES.TORNEOS}
            icon={<Cup width={'20px'} height={'20px'} fillOpacity='1' />}
            label={t('navbar.gamesMenu.tournaments')}
          />
          <Item
            to={ROUTES.TREASURES_GAME}
            icon={<BiGhost size={'20px'} />}
            label={t('navbar.gamesMenu.miniGames')}
            isNew
          />
          <Item
            to={ROUTES.GAMES}
            icon={<Games width='20px' height='20px' />}
            label={t('navbar.gamesMenu.gameCatalog')}
          />
          <Item
            to={ROUTES.RANKING}
            icon={<Medal width='20px' height='20px' />}
            label={t('navbar.communityMenu.ranking')}
          />
        </Group>
        <Group title={t('navbar.community')} showDivider>
          <Item
            to={ROUTES.POLLS}
            icon={<FrontHand width='20px' />}
            label={t('navbar.communityMenu.polls')}
            isNew
          />
          <Item
            to={ROUTES.OPPORTUNITIES}
            icon={<Case width='20px' height='20px' />}
            label={t('navbar.communityMenu.opportunities')}
          />
          {/* <Item
            to={'/landing/aprende'}
            icon={<FilledBook width='20px' height='20px' />}
            label={t('navbar.communityMenu.courses')}
          /> */}
          <Item
            to={ROUTES.B2B}
            icon={<Allies width='20px' height='20px' />}
            label={t('navbar.communityMenu.alliesWithOla')}
          />
        </Group>
        <Group title={t('navbar.news')} showDivider>
          <Item
            to={ROUTES.NEWS}
            icon={<NewsIcon width='20px' height='20px' />}
            label={t('navbar.newsMenu.alpha')}
          />
          <Item
            to={ROUTES.EVENTS}
            icon={<CalendarDayCircle width='20px' height='20px' />}
            label={t('navbar.newsMenu.events')}
          />
        </Group>
        <Group title={t('navbar.store')} showDivider>
          <Item
            to={ROUTES.MARKETPLACE}
            icon={<Market width={'20px'} height={'20px'} />}
            label={t('navbar.storeMenu.store')}
          />
        </Group>
        <Group showDivider>
          <Item
            to={ROUTES.ABOUT}
            icon={<FiInfo size={'21px'} />}
            label={t('navbar.about_ola')}
          />
        </Group>

        {!isMobile && (
          <Flex
            my='5'
            w='100%'
            cursor='pointer'
            onClick={handleToggleMenu}
            justifyContent={isOpen ? 'flex-end' : 'center'}
          >
            <Box
              p='1'
              borderRadius='full'
              _hover={{ bgColor: Menu.bgColorHover }}
              transition='transform 0.3s ease-in-out'
              transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            >
              <IoChevronForwardOutline color='#CCC' />
            </Box>
          </Flex>
        )}
      </UnorderedList>
    </Flex>
  )
}

export default LeftMenu