import {
    Flex,
    Button,
    CircularProgress,
    CircularProgressLabel,
    Image,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Medal from './assets/medal.png'
import { useOnboardingStore } from '@olagg/store'
import { useTranslation } from 'react-i18next'
import XpPill from '../XpPill'
import useQuest from '../../modules/Quests/hooks/useQuest'
import { Link } from 'react-router-dom'

const CompletionSnack = () => {

    const { getObQuests, obQuests } = useOnboardingStore()
    const [completion, setCompletion] = useState(0)
    
    const { t, i18n } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [groupedQuests, setGroupedQuests] = useState({})

    const getProfileCompletion = () => {
        let total = obQuests?.quests ? obQuests?.quests.length : 0 // 100%
        let claimed = obQuests?.quests ? obQuests?.quests.filter(obq => obq?.status == 'CLAIMED') : 0

        let percentage = (claimed.length * 100) / total
        setCompletion(percentage)
    }

    const checkSnackStatus = () => {
        let now = new Date()
        let lastSaved = localStorage.getItem('completion_snack_date_closed')
        let last = lastSaved ? new Date(lastSaved) : now

        const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000 // 7 días en milisegundos
        // const sevenDaysInMs = 1 * 60 * 1000 // 1 min en milisegundos Test
        const hasSevenDaysPassed = (now.getTime() - last.getTime()) >= sevenDaysInMs;

        if (hasSevenDaysPassed || !lastSaved) setIsOpen(true)
    }

    const groupByTrigger = (quests) => {
        let group = {
            "others": [
                "user.name.set",
                "user.avatar.updated",
                "user.email.verified",
                "user.passkey.registered",
                "user.deposit-wallet.created",
                "user.verified.level-1",
                "user.verified.level-2"
            ],
            "linked_accounts": [
                "quest.discord.linked",
                "quest.twitter.linked",
                "quest.google.linked"
            ],
            "interests": [
                "user.opportunity.added",
                "user.platform.added",
                "user.category.added"
            ]
        };

        let result = {
            linked_accounts: [],
            others: [],
            interests: []
        };

        quests.forEach(quest => {
            const trigger = quest.trigger;

            if (group.linked_accounts.includes(trigger)) {
                result.linked_accounts.push(quest);
            } else if (group.others.includes(trigger)) {
                result.others.push(quest);
            } else if (group.interests.includes(trigger)) {
                result.interests.push(quest);
            }
        });
        setGroupedQuests(result)
    };

    useEffect(() => {
        getObQuests()
        checkSnackStatus()
    }, [])

    useEffect(() => {
        getProfileCompletion()
        if (obQuests?.quests?.length > 0) groupByTrigger(obQuests?.quests)
    }, [obQuests])

    const handleClose = () => {
        if (completion < 100) {
            let now = new Date()
            localStorage.setItem('completion_snack_date_closed', now.toISOString())
        }
        setIsOpen(false)
    }

    const icons = {
        "quest.discord.linked": "/discord.svg",
        "quest.google.linked": "/google.svg",
        "quest.twitter.linked": "/x.svg",
    }

    const title = (quest) => {
        const _title = i18n.language === 'es' ? quest.title : quest.extraData?.[i18n.language]?.title || quest.title
        if (quest.recursive) {
            const n = quest.iteration * quest.multiplier
            return _title?.replace('[N]', n.toString())
        }
        return _title
    }

    const getXpData = (trigger) => {
        const triggerEvent = trigger
        let totalXp = 0
        let claimed = false
        let quest = obQuests?.quests ? obQuests?.quests.filter(f => f.trigger == triggerEvent) : []
        if (quest.length > 0) {
            totalXp = quest[0].score
            if (quest[0].status == 'CLAIMED') claimed = true
        }
        return { total: totalXp, claimed: claimed }
    }

    const [selectedQuest, setSelectedQuest] = useState({})
    const [isClaiming, setIsClaiming] = useState(false)
    const { claimQuest } = useQuest()
    const handleClaim = async (currentQuest) => {
        if (currentQuest.status == 'COMPLETED') {
            setSelectedQuest(currentQuest)
            setIsClaiming(true)
            await claimQuest(currentQuest)
            getObQuests()
            setIsClaiming(false)
            setSelectedQuest({})
        }
    }

    return (
        <>
            {isOpen && !isNaN(completion) && completion < 100 && (
                <Flex borderRadius={'12px'} maxW={{ base: '100%', md: '412px' }} minW={{ base: '100%', md: '412px' }} w={{ base: '100%', md: '412px' }} bgColor='#110434' boxShadow='-3px -2px 24px 0px rgba(254, 173, 16, 0.70)'>
                    <Accordion allowToggle maxW={{ base: '100%', md: '412px' }} minW={{ base: '100%', md: '412px' }} w={{ base: '100%', md: '412px' }}>
                        <AccordionItem border={'none'}>
                            <AccordionButton m='0' p='0'>
                                <Flex gap='8px' alignItems={'center'} w='full' bgColor='#3F2091' minHeight='64px' p='16px 24px' border={'1px solid #FEAD10'} borderRadius={'12px'}>
                                    <Flex>
                                        <CircularProgress size={'40px'} capIsRound value={completion} color='#07E588' trackColor='rgba(255, 255, 255, 0.20)' thickness='10'>
                                            <CircularProgressLabel>
                                                <Image src={Medal} w={'30px'} h={'30px'} m={'5px'} />
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </Flex>
                                    <Flex flexDirection={'column'}>
                                        <Text color='white' fontWeight={'700'} fontSize='14px' lineHeight={'16px'}>
                                            {/* {Math.trunc(completion)}% {t('ProfileCompletion.profileCompletionPercentage')} */}
                                            {t('ProfileCompletion.almostDoneProfile')}
                                        </Text>
                                    </Flex>
                                    <AccordionIcon color={'white'} mt='2px' ml='auto' />
                                </Flex>

                            </AccordionButton>
                            <AccordionPanel p='16px'>
                                <Flex flexDirection={'column'} gap='12px'>
                                    {Object.keys(groupedQuests).map((gq, i) => (
                                        gq != 'others' ? (
                                            <Flex key={i} w='100%' bgColor='#1F1144' borderRadius={'12px'} p='16px 8px' flexDirection={'column'} gap='16px'>
                                                <Accordion allowToggle>
                                                    <AccordionItem border={'none'}>
                                                        <AccordionButton m='0' p='0'>
                                                            <Flex>
                                                                <Text color='white' fontSize='14px' fontWeight='700'>{t(`ProfileCompletion.group.${gq}`)}</Text>
                                                            </Flex>
                                                            <AccordionIcon color={'white'} mt='2px' ml='auto' />
                                                        </AccordionButton>
                                                        <AccordionPanel p='0' mt='16px'>
                                                            <Flex gap='16px' w='full' flexDirection={'column'}>
                                                                {groupedQuests[gq]?.map((q, k) => (
                                                                    <Flex flexDirection={'row'} gap='16px'>
                                                                        <Link to={`/${i18n.language}/mi-perfil`}>
                                                                            <Flex flexDirection={'row'} gap='8px'>
                                                                                {icons[q.trigger] && (
                                                                                    <Flex>
                                                                                        <img width={'18px'} src={icons[q.trigger]} alt={title(q)} />
                                                                                    </Flex>
                                                                                )}
                                                                                <Text textDecoration={q.status == 'CLAIMED' ? 'line-through' : 'none'} color='white' fontSize='14px' fontWeight='400'>{title(q)}</Text>
                                                                            </Flex>
                                                                        </Link>
                                                                        <Flex ml='auto'>
                                                                            <Flex onClick={() => handleClaim(q)} cursor={q.status == 'COMPLETED' ? 'pointer' : 'default'}>
                                                                                <XpPill
                                                                                    v1
                                                                                    score={getXpData(q.trigger).total}
                                                                                    active={q.status == 'CLAIMED'}
                                                                                    showCheck={q.status == 'CLAIMED'}
                                                                                    isCompleted={q.status == 'COMPLETED'}
                                                                                    isLoading={q.id == selectedQuest?.id && isClaiming ? true : false}
                                                                                />
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                ))}
                                                            </Flex>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </Flex>
                                        ) : (
                                            groupedQuests[gq]?.map((q, k) => (
                                                <Flex key={i} w='100%' bgColor='#1F1144' borderRadius={'12px'} p='16px 8px' flexDirection={'column'} gap='16px'>
                                                    <Flex flexDirection={'row'} gap='16px'>
                                                        <Link to={`/${i18n.language}/mi-perfil`}>
                                                            <Flex>
                                                                <Text textDecoration={q.status == 'CLAIMED' ? 'line-through' : 'none'} color='white' fontSize='14px' fontWeight='400'>{title(q)}</Text>
                                                            </Flex>
                                                        </Link>
                                                        <Flex ml='auto'>
                                                            <Flex onClick={() => handleClaim(q)} cursor={q.status == 'COMPLETED' ? 'pointer' : 'default'}>
                                                                <XpPill
                                                                    v1
                                                                    score={getXpData(q.trigger).total}
                                                                    active={q.status == 'CLAIMED'}
                                                                    showCheck={q.status == 'CLAIMED'}
                                                                    isCompleted={q.status == 'COMPLETED'}
                                                                    isLoading={q.id == selectedQuest?.id && isClaiming ? true : false}
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            ))
                                        )
                                    ))}
                                </Flex>
                                <Flex color='white' w={'full'} justifyContent='center' paddingY='16px'>
                                    <Button variant={'outline'} onClick={handleClose} w='full'>
                                        <Text fontWeight={'700'} fontSize='14px'>
                                            {
                                                completion < 100 ? (
                                                    t('ProfileCompletion.later')
                                                ) : (
                                                    t('ProfileCompletion.close')
                                                )
                                            }
                                        </Text>
                                    </Button>
                                </Flex>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </Flex>

            )}
        </>
    )
}

export default CompletionSnack
